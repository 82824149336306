/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { MdAttachMoney, MdKeyboardArrowLeft, MdTimer } from 'react-icons/md';
import Countdown from 'react-countdown';
import { IoLogoWhatsapp } from 'react-icons/io5';
import copy from 'copy-to-clipboard';
import api from '../../services/api';
import formatPrice from '../../helpers/formatPrice';
import {
  BenefitClub,
  Container,
  ModalPix,
  ProductModal,
  ProductModalFooter,
  ProductModalTag,
  PromotionTime,
} from './styles';
import getIconByName from '../../helpers/getIconByName';
import Loading from '../../components/Loading';

export const ShowProduct = () => {
  const [product, setProduct] = useState();
  const [isLoading, seIsLoading] = useState(true);
  const { product_id } = useParams();
  const [activeModalPix, setActiveModalPix] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('categoriaId');

  const loadProductById = async () => {
    try {
      const response = await api.get(`/products/qr_ads/product/${product_id}`);

      setProduct(response.data);
      seIsLoading(false);
    } catch (error) {
      console.log('erro ao carregar produto');
    }
  };

  const countdownRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Promoção finalizada</span>;
    }
    return (
      <strong>
        {`${days && `${days} dias`} ${hours}:${minutes}:${seconds}`}
      </strong>
    );
  };

  useEffect(() => {
    loadProductById();
  }, []);

  function openModalPix(pix) {
    copy(pix);
    setActiveModalPix(true);
  }

  console.log({ product });
  return (
    <>
      {product && !isLoading && (
        <Container>
          <ProductModal>
            <Carousel
              itemsToScroll={1}
              itemsToShow={1}
              showArrows={false}
              enableAutoPlay={false}
              autoPlaySpeed={3000}
              pagination={!!product.product_images.length}
            >
              <div className="image">
                <img src={product.photo_url} alt="produto" />
              </div>

              {!!product.product_images.length &&
                product.product_images.map(productImage => (
                  <div key={productImage.id} className="image">
                    <img src={productImage.photo_url} alt="produto" />
                  </div>
                ))}
              {product.is_illustrative_image && (
                <ProductModalTag>Imagem meramente ilustrativa</ProductModalTag>
              )}
            </Carousel>

            <span>{product.name}</span>

            {!!product.description && (
              <div className="description">{product.description}</div>
            )}

            {!!product.product_variations.length && (
              <div className="variations">
                {product.product_variations.map(variation => (
                  <div>
                    <span>{`${variation.name} -`}</span>
                    <strong>{formatPrice(variation.price)}</strong>
                  </div>
                ))}
              </div>
            )}

            {product.allow_promotion_time && product.show_promotion_time && (
              <>
                <PromotionTime style={{ padding: '0 21px' }}>
                  <span>Termina em:</span>
                  <div>
                    <MdTimer size={18} color="#ff0000" />
                    <Countdown
                      date={new Date(product.promotion_time)}
                      renderer={countdownRender}
                    />
                  </div>
                </PromotionTime>

                {product.show_benefit_club && (
                  <BenefitClub>
                    <span>Oferta Exclusiva</span>
                    <strong>Clube de Benefícios</strong>
                  </BenefitClub>
                )}
              </>
            )}

            {!!product.value && !product.product_variations.length && (
              <div className="price">
                <strong
                  className={`price-value
                    ${product.value_discount ? 'discount' : ''}`}
                >
                  {formatPrice(product.value)}
                </strong>
                {!!product.value_discount && (
                  <p className="price-discount">
                    {formatPrice(product.value_discount)}
                  </p>
                )}
              </div>
            )}

            <ProductModalFooter>
              <div>
                {product.qr_ads.link_whatsapp && (
                  <a
                    className="whatsapp_button"
                    href={product.qr_ads.link_whatsapp}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoWhatsapp size={20} color="#fff" />
                  </a>
                )}
              </div>

              <div>
                {product.qr_ads.bank_name && product.qr_ads.key_pix && (
                  <button
                    type="button"
                    className="pix_button"
                    onClick={() => openModalPix(product.qr_ads.key_pix)}
                  >
                    <MdAttachMoney size={16} color="#363636" />

                    <span>Pix</span>
                  </button>
                )}

                {!!product.button_name && !!product.link_buy ? (
                  <a
                    className="buy_button"
                    href={product.link_buy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {getIconByName(product.qr_ads.icon_name)}
                    <span>{product.button_name}</span>
                  </a>
                ) : (
                  !!product.qr_ads.button_name &&
                  !!product.qr_ads.link_buy && (
                    <a
                      className="buy_button"
                      href={product.qr_ads.link_buy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getIconByName(product.qr_ads.icon_name)}
                      <span>{product.qr_ads.button_name}</span>
                    </a>
                  )
                )}
              </div>
            </ProductModalFooter>

            <div className="footer-close">
              <button
                type="button"
                onClick={() =>
                  history.push(
                    `/user/${product.user.nickname}/qrads/${product.qr_ads_id}?categoriaId=${categoryId}`,
                  )
                }
              >
                <MdKeyboardArrowLeft color="#363636" size={30} />
              </button>
            </div>
          </ProductModal>
        </Container>
      )}

      {isLoading && <Loading />}

      {activeModalPix && (
        <ModalPix onClick={() => setActiveModalPix(false)}>
          <div>
            <span>{`A chave ${product.qr_ads.bank_name} foi copiada com sucesso`}</span>
            <span>{` Chave: ${product.qr_ads.key_pix}`}</span>
          </div>
        </ModalPix>
      )}
    </>
  );
};
